<!-- 主页全局配置 -->
<template>
	<div>
		<!-- overflow: hidden;超出范围隐藏 -->
		<el-container style="position: absolute;left: 0; top: 0; bottom: 0;right: 0;overflow: hidden;">
			<!-- 顶部导航 -->
			<el-header class="d-flex align-items-center" style="background: #f7f7f7;height: 44px;padding: 0 10px;">
				<p class="h5 mb-0 mr-auto" style="color: #095abd;">AiLink</p>
				<el-menu :default-active="navBar.active" mode="horizontal" @select="handleSelect" text-color="#fff" active-text-color="#ffd04b" background-color="#f7f7f7">
					<el-submenu index="100">
						<template slot="title">
							<!-- 登录后显示的头像和用户名 -->
							<el-avatar size="small"
								:src="user.avatar ? user.avatar : 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png'"></el-avatar>
							{{user.name}}
						</template>
						<div  v-if="token == false">
							<el-menu-item index="100-1" style="color: #000;">登录</el-menu-item>
						</div>
						<div v-else>
							<el-menu-item index="100-2" style="color: #000;">账号设置</el-menu-item>
							<el-menu-item index="100-3" style="color: #000;">退出</el-menu-item>
						</div>
					</el-submenu>
				</el-menu>
			</el-header>

			<!-- 内容 -->
			<el-container style="height: 100%;">

				<!-- 右边布局 -->
				<el-main class="bg-light" style="position: relative;background-image: linear-gradient(to top, #d2edf7,#f7f7f7);"  v-loading="loading">
					<router-view></router-view>

					<!-- 回到顶部 -->
					<el-backtop target=".el-main" :bottom="100">
						<div style="{height: 100%;width: 100%;
							background-color: #f2f5f6;
							box-shadow: 0 0 6px rgba(0,0,0, .12);
							text-align: center;
							line-height: 40px;
							color: #1989fa;}">
							UP
						</div>
					</el-backtop>
					
				</el-main>
			</el-container>
			<el-footer style="height: 42px;background: #fff;">
				<div class="d-flex align-items-center" style="height: 42px;background: #fff;">
					<div class="d-flex align-items-center" style="justify-content: center;width: 50%;" @click="dbdh(0)">
						<div style="font-size: 14px;">问答</div>
					</div>
					<div class="d-flex align-items-center" style="justify-content: center;width: 50%;" @click="dbdh(1)">
						<div style="font-size: 14px;">工具</div>
					</div>
					<div class="d-flex align-items-center" style="justify-content: center;width: 50%;" @click="dbdh(2)">
						<div style="font-size: 14px;">我的</div>
					</div>
				</div>
			</el-footer>
		</el-container>
		<!-- <router-view></router-view> -->
	</div>
</template>

<script>
	import common from '@/common/mixins/common.js'; // 引入 全局共公样式
	import { mapState } from 'vuex'; // 引入 全局 公共数据
	export default {
		mixins: [common],
		provide() {
			return {
				layout: this
			}
		},
		data() {
			return {
				bran: [],
				loading: false,
				dqym:0
			}
		},
		created() {
			// 获取面包屑导航
			this.getRouterBran()
			// 初始化选中菜单
			this.__initNavBar()
			const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
			
			if (isMobile) {
				
				// console.log('当前在手机端');
			} else {
				location.href="http://chatgpt.lzy0752.com"
				// console.log('当前在PC端');
			}
		},
		// 页面监听器
		watch: {
			'$route'(to, from) {
				this.navBar.list.forEach((item, index) => {
					let i = item.submenu.findIndex(v => v.pathname === to.name)
					if (i !== -1) {
						this.navBar.active = index.toString()
						this.slideMenuActive = i.toString()
					}
				})

				// 本地存储
				localStorage.setItem('navActive', JSON.stringify({
					top: this.navBar.active || '0',
					left: this.slideMenuActive || '0'
				}))
				this.getRouterBran()
			}
		},
		// 计算属性
		computed: {
			// 引入store-modules的user模块里面的user(存储用户相关的数据)
			...mapState({
				user: state => state.user.user,
				navBar: state => state.menu.navBar,
				token: state => state.user.token
			}),
			slideMenuActive: {
				get() {
					let item = this.navBar.list[this.navBar.active]
					return item ? item.subActive : '0'
				},
				set(val) {
					let item = this.navBar.list[this.navBar.active];
					if (item) {
						item.subActive = val;
					}
				}
			},
			slideMenus() {
				let item = this.navBar.list[this.navBar.active]
				return item ? item.submenu : []
			}
		},
		// 点击导航获得索引
		methods: {
			// 显示loading
			showLoading() {
				this.loading = true
			},
			// 隐藏loading
			hideLoading() {
				this.loading = false
			},
			
			// 获取本地存储
			__initNavBar() {
				let r = localStorage.getItem('navActive')
				if (r) {
					r = JSON.parse(r)
					this.navBar.active = r.top
					this.slideMenuActive = r.left
				}
			},
			// 获取面包屑导航
			getRouterBran() {
				let b = this.$route.matched.filter(v => v.name)
				let arr = []
				b.forEach((v, k) => {
					// 过滤layout和index
					if (v.name === 'index' || v.name === 'layout') return
					arr.push({
						name: v.name,
						path: v.path,
						title: v.meta.title
					})
				})
				if (arr.length > 0) {
					arr.unshift({
						name: 'index',
						path: '/index',
						title: '后台首页'
					})
				}
				this.bran = arr
			},
			// 顶部导航 
			handleSelect(key, keyPath) {
				if (key === '100-1') {
					return this.logout()
				}
				if (key === '100-2') {
					this.$router.push({
						name: 'gpt_mysz'
					})
				}
				if (key === '100-3') {
					// 退出登录
					return this.logout()
				}
				this.navBar.active = key
				// 默认选中跳转到当前激活
				this.slideMenuActive = '0'
				if (this.slideMenus.length > 0) {
					this.$router.push({
						name: this.slideMenus[this.slideMenuActive].pathname
					})
				}
			},
			dbdh(e){
				if(this.dqym == e){
					
				}else{
					if(e == 0){
						this.dqym = e
						this.$router.push({
							name: 'index'
						})
					}else if(e == 1){
						this.dqym = e
						this.$router.push({
							name: 'gpt_czgj'
						})
					}else{
						this.dqym = e
						this.$router.push({
							name: 'index_my'
						})
					}
				}
				
			},
			slideSelect(key, keyPath) {
				this.slideMenuActive = key
				// 跳转到指定页面
				this.$router.push({
					name: this.slideMenus[key].pathname
				})
			},
			// 退出登录
			logout() {
				this.axios.post('/admin/logout', {}, {
					token: true,
					loading: true
				}).then(res => {
					this.$message('退出成功')
					// 清除状态和存储
					this.$store.commit('logout')
					// 返回到登录页
					this.$router.push({
						name: "login"
					})
				}).catch(err => {
					console.log(err.response);
					// 清除状态和存储
					this.$store.commit('logout')
					// 返回到登录页
					this.$router.push({
						name: "login"
					})
				})
			}

		}
	}
</script>

<style>
.leftbuju{
	color: #FFFFFF;
}
.leftbuju:hover{
	color: #1f2138;
}
</style>
